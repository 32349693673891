let $ = require('jquery');
require('slick-carousel');
require('slick-carousel/slick/slick.scss');
require('slick-carousel/slick/slick-theme.scss');


$( document ).ready(function() {
    let $devices = $('#quick_service_form_device');
    let $manufacturers = $('#quick_service_form_manufacturer');
    let $token = $('#quick_service_form__token');

    function resetImageField(){
        $('.cell-phone-background').removeClass("d-none");
        $('.cell-phone-image').empty();
    }




    $(document).on('change','#quick_service_form_device',function ()
    {
        let $form = $(this).closest('form');
        let data = {};

        data[$token.attr('name')] = $token.val();
        data[$devices.attr('name')] = $devices.val();

        $.post($form.attr('action'), data).then(function(response)
        {

            $('#quick_service_form_manufacturer').replaceWith(
                $(response).find('#quick_service_form_manufacturer')
            )
            $('#quick_service_form_model').replaceWith(
                $(response).find('#quick_service_form_model')
            )

        })

        resetImageField();

    });


    $(document).on('change', '#quick_service_form_manufacturer',function ()
    {
        let $form = $(this).closest('form');
        let $devices = $('#quick_service_form_device');
        let $manufacturers = $('#quick_service_form_manufacturer');
        let data = {};

        data[$token.attr('name')] = $token.val();
        data[$devices.attr('name')] = $devices.val();
        data[$manufacturers.attr('name')] = $manufacturers.val();

        $.post($form.attr('action'), data).then(function(response)
        {

            $('#quick_service_form_model').replaceWith(
                $(response).find('#quick_service_form_model')
            )

        })

        resetImageField()

    });

    $(document).on('change', '#quick_service_form_model', function ()
    {

        let $form = $(this).closest('form');
        let $devices = $('#quick_service_form_device');
        let $manufacturers = $('#quick_service_form_manufacturer');
        let $models = $('#quick_service_form_model');
        let data = {};

        data[$token.attr('name')] = $token.val();
        data[$devices.attr('name')] = $devices.val();
        data[$manufacturers.attr('name')] = $manufacturers.val();
        data[$models.attr('name')] = $models.val();

        $.post($form.attr('action'), data).then(function(response)
        {

            $('#quick_service_form_repairs').replaceWith(
                $(response).find('#quick_service_form_repairs')
            );

            $('#quick_service_form_repairs').children('label').each(function () {
                let input = document.getElementById(this.htmlFor);
                let percent = input.getAttribute('data-service-discount-percent');

                let tdDesc = document.createElement("td");
                let tdPrice = document.createElement("td");
                tdPrice.className = 'text-right';

                if(input.getAttribute('data-service-description') !== ''){

                    tdDesc.innerHTML = this.innerHTML + '&nbsp;<span data-toggle="tooltip" data-placement="top" title="' + input.getAttribute('data-service-description') + '"><i class="fas fa-question-circle"></i></span>';
                }
                else {
                    tdDesc.innerHTML = this.innerHTML;
                }

                let price;
                let oldPrice;
                if(percent > 0) {
                    oldPrice = input.getAttribute('data-service-price');
                    price = input.getAttribute('data-service-discount-price');
                    tdPrice.innerHTML = '<div class="discount-price"><span class="price mb-0">' + price +'&nbsp;€</span><span class="badge badge-pill badge-info">' + percent + '%</span><span class="old-price">' + oldPrice + '&nbsp;€</span></div>';
                }
                else {
                    price = input.getAttribute('data-service-price');
                    tdPrice.innerHTML = price + '€';
                }
                //this.append(price);
                //console.log(input.getAttribute('data-service-description'));

                let smallDesc = document.createElement("small"); //<small></small>
                smallDesc.innerHTML = input.getAttribute('data-service-description');
                smallDesc.className = 'p-2'

                let priceDescTd = document.createElement("td");
                let priceDescTable = document.createElement("table");
                priceDescTable.className = 'w-100';
                let priceDescFirstTr = document.createElement("tr");
                //let priceDescSecondTr = document.createElement("tr");

                priceDescFirstTr.appendChild(tdDesc);
                priceDescFirstTr.appendChild(tdPrice);

                //priceDescSecondTr.appendChild(smallDesc);

                priceDescTable.appendChild(priceDescFirstTr);
                if(input.getAttribute('data-service-description') !== ''){
                    //priceDescTable.appendChild(priceDescSecondTr);
                }
                priceDescTd.appendChild(priceDescTable);

                this.innerHTML = '';

                this.appendChild(priceDescTd);


                //this.appendChild(tr);

                /*this.innerHTML = '<tr><td>' + this.innerHTML + '</td><td>' + price + ' kn</td><tr>';*/
            });

        })

        /* change model image */
        resetImageField();
        let imagePath = $models.find(':selected').attr('data-model-image');
        if(imagePath !== ''){
            $('.cell-phone-background').addClass("d-none");
            let elem = document.createElement("img");
            elem.setAttribute("src", imagePath);
            elem.classList.add('d-block');
            elem.classList.add('mx-auto');
            $('.cell-phone-image').append(elem);
        }

        $('.repair-dummy').addClass("d-none");



    });

    /*$('#quick-service-responsive').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 4,
        nextArrow: $('.next-service'),
        prevArrow:$('.prev-service'),
        variableWidth: false,
        centerMode: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: false,
                    dots: false
                }
            }
        ]
    });*/



    /* OBRAČUN TROŠKOVA */
    let cart = [];
    if (typeof(Storage) !== "undefined") {
        if (localStorage.cart)
        {
            cart = JSON.parse(localStorage.cart);
            //console.log('ima kosarica');
        }
        showCart();
    } else {
        // Sorry! No Web Storage support..
    }

    $(document).on('click','#quick_service_form_repairs input[type=checkbox]',function() {

        let repairPrice;
        let percent = this.getAttribute('data-service-discount-percent');
        if(percent > 0) {
            repairPrice = this.getAttribute('data-service-discount-price');
        }
        else {
            repairPrice = this.getAttribute('data-service-price');
        }

        let repairName = this.getAttribute('data-service-name');

        if ( $(this).is(':checked') ) {
            /* ADD TO CART */
            addToCart(repairName, repairPrice)
        }
        else {
            /* REMOVE FROM CART */
            removeItemFromCart(repairName, repairPrice)

        }
    });

    function addToCart(repairName, repairPrice)
    {

        let item = { Product: repairName,  Price: repairPrice };
        cart.push(item);

        showCart()
    }

    function removeItemFromCart(repairName, repairPrice)
    {
        for (let i in cart) {
            let item = cart[i];
            if (item.Product === repairName){
                cart.splice(i,1); // delete item at index
                showCart();
                return;
            }
        }

    }

    function showCart()
    {

        let cartWrapper = $("#quick-service-cart");

        cartWrapper.html(
            cartTemplate()
        );

    }

    function cartTemplate()
    {
        let sum = 0;
        let webDiscountPrice = 15;
        if (cart.length !== 0) {
            for (let i in cart) {
                let item = cart[i];
                sum += parseFloat(item.Price);
            }
        }
        sum = sum.toFixed(2);

        let pdvStopa = ((100*25) / (100 + 25)).toFixed(3);

        let basePrice = (sum - ((sum * pdvStopa)/100)).toFixed(2);
        let vatAmount = ((sum * pdvStopa)/100).toFixed(2);

        return '<div class="row">' +
            '                <div class="col-4">' +
            '                    <p>Osnovica</p>' +
            '                </div>' +
            '                <div class="col-8 text-right">' +
            '                    <p class="service-base-price">' + basePrice + '&nbsp;€</p>' +
            '                </div>' +
            '            </div>' +
            '            <div class="row">' +
            '                <div class="col-4">' +
            '                    <p>PDV</p>' +
            '                </div>' +
            '                <div class="col-8 text-right">' +
            '                    <p class="service-pdv-price">' + vatAmount + '&nbsp;€</p>' +
            '                </div>' +
            '            </div>' +
            '            <div class="row">' +
            '                <div class="col-4">' +
            '                    <p>Ukupno</p>' +
            '                </div>' +
            '                <div class="col-8 text-right">' +
            '                    <p class="service-subtotal-price">' + sum + '&nbsp;€</p>' +
            '                </div>' +
            '            </div>' +
            '            <hr />' +
            '            <div class="row">' +
            '                <div class="col-5">' +
            '                    <p>Dostava</p>' +
            '                </div>' +
            '                <div class="col-7 text-right">' +
            '                    <p class="service-shipping">GRATIS</p>' +
            '                </div>' +
            '            </div>' +
            '            <hr />' +
            '            <div class="row">' +
            '                <div class="col-6">' +
            '                    <p>WEB POPUST</p>' +
            '                </div>' +
            '                <div class="col-6 text-right">' +
            '                    <p class="service-return-price">-' + webDiscountPrice +' €</p>' +
            '                </div>' +
            '            </div>' +
            '            <hr />' +
            '            <div class="row">' +
            '                <div class="col-4">' +
            '                    <p class="service-total"><b>TOTAL</b></p>' +
            '                </div>' +
            '                <div class="col-8 text-right">' +
            '                    <p  class="service-total-price">' + Math.max(0,(sum - webDiscountPrice)).toFixed(2) + ' €</p>' +
            '                </div>' +
            '            </div>';
    }





});